<template>
    <v-container fluid>
      <v-row align="center"
      justify="center">
            <div v-show="refState.signatureData && initial">
              <img :src="refState.signatureData" />
              <v-btn color="primary" @click.native="recapture" block >Recapture</v-btn>
            </div>
      </v-row>
      <v-row align="center"
      justify="center">
            <div v-show="!initial">
              <canvas ref="signaturePad"></canvas>
              <v-btn color="primary" @click.native="undo" block >Clear</v-btn>
            </div>
      </v-row>
        <!-- <v-flex xs12 class="text-xs-center"> -->
            <!-- <v-flex xs6>
                <v-btn color="accent" @click.native="save" xs6 class="white--text">Save</v-btn>
            </v-flex> -->
        <!-- </v-flex> -->
    </v-container>
</template>

<script>
import * as SignaturePad from 'signature_pad'

export default {
  name: 'SignaturePad',
  props: ['state', 'render'],
  data() {
    return {
      refState: this.state,
      initial: true,
    }
  },
  created() {
    const vm = this
    this.$on(
      'signaturepad:render',
      function () {
        setTimeout(() => {
          if (typeof this.$refs.signaturePad === 'undefined') {
            return
          }
          this.signaturePad = new SignaturePad.default(this.$refs.signaturePad, {
            onEnd: this.save,
            // dotSize: 50
            throttle: 1,
            minDistance: 1,
          })

          const resizeCanvas = () => {
            if (typeof this.$refs.signaturePad === 'undefined') {
              return
            }
            const canvas = this.$refs.signaturePad
            // When zoomed out to less than 100%, for some very strange reason,
            // some browsers report devicePixelRatio as less than 1
            // and only part of the canvas is cleared then.
            var ratio = Math.max(window.devicePixelRatio || 1, 1)
            canvas.width = canvas.offsetWidth * ratio
            canvas.height = canvas.offsetHeight * ratio
            canvas.getContext('2d').scale(ratio, ratio)
            this.signaturePad.clear() // otherwise isEmpty() might return incorrect value
            // if(typeof this.refState.signatureData === 'undefined'){
            //     this.refState = {
            //         signatureData: ''
            //     };
            // }
            setTimeout(() => {
              this.refState.signatureData = ''
            })
          }

          // FIXME for optional value for preloading signature
          // signaturePad.fromDataURL();

          window.addEventListener('onresize', function () {
            resizeCanvas()
          })
          resizeCanvas()
        }, 100)
      }.bind(vm),
    )
  },
  watch: {
    render() {
      this.recapture()
    },
  },
  methods: {
    undo() {
      this.signaturePad.clear()
    },
    recapture() {
      this.initial = false
      this.$emit('signaturepad:render')
    },
    save() {
      if (!this.signaturePad.isEmpty()) {
        this.refState.signatureData = this.signaturePad.toDataURL()
      }
    },
  },
}
</script>

<style scoped>
.wrapper {
  width: 100%;
  margin: auto;
}

canvas,
button {
  width: 800px;
  margin: auto;
}

canvas {
  z-index: 1;
  background: white;
  border: black 3px solid;
  border-radius: 8px;
  height: 200px;
}
</style>
